/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components), {Video} = _components;
  if (!Video) _missingMdxReference("Video", true);
  return React.createElement(React.Fragment, null, React.createElement(GatsbyImage, {
    alt: "alt text",
    style: {
      maxWidth: "1000px"
    },
    image: getImage(props.data.mdx.frontmatter.embeddedImagesLocal[0])
  }), "\n", React.createElement(_components.p, null, "Dish Dances is a movement education activity presented by Ange Loft (Kanien'kehá:ka), Talking Treaties lead artist and Indigenous Artist-in-Residence at OISE. It invites viewers into conversation with the symbols, embodied gestures, and land-based knowledges held in the Dish With One Spoon agreement."), "\n", React.createElement(Video, {
    playing: "false",
    url: "https://www.youtube.com/watch?v=j2jYEUHUZ9c",
    posterFrame: "",
    mode: "FULL_WIDTH",
    title: "Dish Dance 1",
    subTitle: "Activity"
  }), "\n", React.createElement(Video, {
    playing: "false",
    url: "https://www.youtube.com/watch?v=c--CgI4XV4s",
    posterFrame: "",
    mode: "FULL_WIDTH",
    title: "Dish Dance 2",
    subTitle: "Activity"
  }), "\n", React.createElement(Video, {
    playing: "false",
    url: "https://www.youtube.com/watch?v=IJySftmYMpg",
    posterFrame: "",
    mode: "FULL_WIDTH",
    title: "Dish Dance 3",
    subTitle: "Activity"
  }), "\n", React.createElement(Video, {
    playing: "false",
    url: "https://www.youtube.com/watch?v=CnKnO3G6Mv4",
    posterFrame: "",
    mode: "FULL_WIDTH",
    title: "Dish Dance 4",
    subTitle: "Activity"
  }), "\n", React.createElement("img", {
    className: "toom dish-belt",
    src: "/tooms/tooms-dish-belt.png"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
